import { Link } from "gatsby";
import React from "react";

const LinkButton = (props) => {
  let dynamicAttributes =
    "text-white bg-blue-600 hover:bg-blue-700 border-transparent";
  if (props.empty) {
    dynamicAttributes = "text-blue-600 bg-white border-blue-600";
  }

  const classes = `${dynamicAttributes} mr-4 w-full inline-flex items-center justify-center px-4 py-2 shadow-sm font-medium border rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 mb-3 sm:w-auto`;

  // if the link is to the same site, we can use <Link>
  if (props.to.startsWith("/")) {
    return (
      <Link to={props.to} className={classes}>
        {props.text}
      </Link>
    );
  } else {
    // if the link is external, we need an <a> element
    return (
      <a href={props.to} className={classes} target="_blank" rel="noreferrer">
        {props.text}
      </a>
    );
  }
};

export default LinkButton;
