import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/Layout";
import LinkButton from "../components/LinkButton";
import { SEO } from "../components/SEO";

const MailingListConfirmation = () => {
  return (
    <Layout>
      <div className="grid cols-1 space-y-12 justify-center items-center">
        <h1 className="font-extrabold block text-5xl text-gray-700 mb-4 text-center">
          <span className="text-blue-500">Thanks</span>, you&apos;re in.
        </h1>
        <div>
          <p className="font-semibold text-xl text-gray-500 text-center">
            You have successfully subscribed to my mailing list.
          </p>
          <p className="font-semibold text-xl text-gray-500 text-center">
            We&apos;ll be in touch.
          </p>
        </div>

        <StaticImage src="../images/subscriber.png" className="max-w-5xl" />
        <LinkButton to="/" text="Go to Homepage" />
      </div>
    </Layout>
  );
};

export const Head = () => <SEO
  title="Mailing List Confirmation"
  pathname="/mailing-list-confirmation"
  description="Mailing List confirmation page for newsletter"
/>

export default MailingListConfirmation;
